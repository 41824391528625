import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

type PhotographyPageProps = {};

const PhotographyPage: React.FunctionComponent<PhotographyPageProps> = () => {
    return (
        <Layout>
            <SEO
                title="Blogs"
                description="Bao Pham's blogs"
            />

        </Layout>
    );
};

export default PhotographyPage;
